<template>
  <div class="flex flex-col">
    <div class="flex justify-between">
      <div class="flex flex-col p-4">
        <h1 class="text-[18px] font-semibold text-primary">Autour du voyage</h1>
        <p>
          {{ `${numberOfResults} résultat${numberOfResults > 1 ? 's' : ''}` }}
        </p>
      </div>
      <NuxtLink class="more" :href="`/autour-du-voyage/carnet-de-voyage`" target="_blank"
        >Voir tout</NuxtLink
      >
    </div>
    <div class="flex justify-between">
      <div v-if="numberOfResults > 0" class="flex w-full items-center bg-white">
        <SliderSwiperArrows
          v-if="numberOfResults > 3"
          v-model="swiper"
          class="hidden p-1 md:flex"
          which="left"
        />
        <SliderSwiperWrapper v-model="swiper" slides-per-view="auto" :active-index="1">
          <SwiperSlide v-for="article of articles" :key="article.objectID">
            <NuxtLink :key="article.objectID" class="p-3" :to="`/articles/${article.slug}`">
              <p class="mb-3 w-[180px] text-sm text-gray-500">
                {{ article.type === 'Article' ? 'Carnet de voyage' : article.tags.join(', ') }}
              </p>
              <h1 class="line-clamp-3 w-[180px] font-semibold">{{ article.title }}</h1>
              <!--
              TODO: add calendar after if it's necesary
              <span>
                <Icon
                  v-if="article?.startDate || article?.endDate"
                  name="calendar"
                  height="15px"
                  width="15px"
                  class="mr-2"
                />

                <p class="w-[180px] self-center text-xs text-gray-500">
                  {{
                    article?.startDate === article?.endDate
                      ? article?.startDate
                      : `${article?.startDate} - ${article?.endDate}`
                  }}
                </p>
              </span> -->
            </NuxtLink>
          </SwiperSlide>
        </SliderSwiperWrapper>
        <SliderSwiperArrows
          v-if="numberOfResults > 3"
          v-model="swiper"
          class="hidden p-3 md:flex"
          which="right"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AlgoliaArticle } from '@/lib/algolia-types/AlgoliaArticle';

import { SwiperBinding } from '@/lib/types/models/swiper';

defineProps<{
  articles: AlgoliaArticle[];
  numberOfResults: number;
}>();

const swiper = ref<SwiperBinding>();
</script>

<style lang="scss" scoped>
@use '$/misc.scss';

.more {
  @include misc.bold-link();

  display: flex;
  align-items: center;
}
</style>
