<template>
  <div class="flex flex-col">
    <div class="flex justify-between">
      <div class="flex flex-col p-4">
        <h1 class="text-[18px] font-semibold text-primary">Voyages</h1>
        <p>
          {{ `${numberOfResults} résultat${numberOfResults > 1 ? 's' : ''}` }}
        </p>
      </div>
      <NuxtLink class="more" :to="`/tous-les-itineraires?search=${search}`" target="_blank"
        >Tous les itinéraires</NuxtLink
      >
    </div>
    <div class="flex justify-between">
      <div v-if="numberOfResults > 0" class="flex w-full items-center bg-white">
        <SliderSwiperArrows
          v-if="numberOfResults > 3"
          v-model="swiper"
          class="hidden p-1 md:flex"
          which="left"
        />
        <SliderSwiperWrapper v-model="swiper" slides-per-view="auto" :active-index="1">
          <SwiperSlide v-for="journey of journeys" :key="journey.objectID">
            <GlobalSearchJourneyCard
              :key="journey.objectID"
              :journey="journey"
              class="search-journey-card"
            />
          </SwiperSlide>
        </SliderSwiperWrapper>
        <SliderSwiperArrows
          v-if="numberOfResults > 3"
          v-model="swiper"
          class="hidden p-3 md:flex"
          which="right"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AlgoliaJourney } from '@/lib/algolia-types/AlgoliaJourney';

import { SwiperBinding } from '@/lib/types/models/swiper';

defineProps<{
  journeys: AlgoliaJourney[];
  numberOfResults: number;
  search: string;
}>();

const swiper = ref<SwiperBinding>();
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/misc.scss';

.more {
  @include misc.bold-link();

  display: flex;
  align-items: center;
}
</style>
